import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HomeShortParallax from "components/ShortParallax/HomeShortParallax.jsx";

import homePageStyle from "assets/jss/material-kit-react/views/homePageStyle.jsx";

import FeaturesSection from "./Sections/FeaturesSection.jsx";
import DescriptionSection from "./Sections/DescriptionSection.jsx";
import PriceSection from "./Sections/PriceSection.jsx";


const dashboardRoutes = [];

class HomePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688"
          }}
          {...rest}/>
        <HomeShortParallax filter image={require("assets/img/background.jpg")} >
          <div className={classes.container}>
            <GridContainer>
              <GridItem >
                <div className={classes.homePage}>
                    <div className={classes.descriptionPlusLink}>
                      <div className={classes.mainDescription}>
                      <h2 className={classes.title}>
                            Write it HERE.
                            <br/>
                            Edit it HERE.
                            <br/>
                            Format it HERE.
                            <br/>
                            Generate epubs HERE.
                        </h2>
                        <h4>
                          This will be where words will go. I don't know what the words will be so I will fill
                          this with pointless filler text. Really it's like diarrhea of the mouth.
                          <br/>
                          <Link className={classes.watchVideoLink}>Watch Video</Link>
                        </h4>
                      </div>
                      <br />
                      <div>
                        <Link
                          to='/registerPage'
                          className={classes.getStartedLink}
                        >
                            Get Started!
                        </Link>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </HomeShortParallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div >
            <DescriptionSection />
            <div >
              <FeaturesSection  />
            </div>
            <PriceSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(homePageStyle)(HomePage);

import { container, title } from "assets/jss/material-kit-react.jsx";

const homePageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  homePage: {
    display:"flex",
    flexDirection: "row",
    width: "65%",
  },  

  mainDescription: {
    display:"flex",
    flexDirection: "column",
    width:"100%",
    float:"left",
    margin:"1em",
    padding:"1em",
    //alignItems:"center",
},
  watchVideoLink: {
    color:"white",
    '&:hover': {
      color: '#0000ee'
    },
    fontWeight: "bolder",
  },
  getStartedLink: {
    backgroundColor: "#009688",
    color:"white",
    '&:hover': {
      color: "#0000ee",
    },
    margin: "2em",
    padding:"1em 2em",
    borderRadius:".5em",
    fontWeight: "bolder",
  },
  descriptionPlusLink: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "1em",
    minHeight: "1em",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem", 
    maxWidth: "3em",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    // margin: "-60px 30px 0px", 
    // borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
}; 

export default homePageStyle;